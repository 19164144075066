import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ExtendedContent from "../components/extendedContent"
import TopBanner from "../components/common/top-banner/top-banner"
import ComponentsFloatingMenu from "../components/graphicModules/components-blocks/floating-menu"
import { StickyContainer } from "react-sticky"
import { createGlobalStyle } from "styled-components"
import { setTopColors } from "../state/app"
import { connect } from "react-redux"
import { saveUrlsToLocalStorage } from "../components/common/type-to-url-match/type-to-url-match"

const GlobalStyles = createGlobalStyle`
	body {
		background: linear-gradient(${({ theme }) => theme.colors.mainPurple} 400px,${({ theme }) => theme.colors.mainWhite} 400px);
	}
`

class ComponentsPage extends React.Component {


  componentDidMount() {
    const { dispatch, pageContext } = this.props
    const entries = this.props.data.entries?.nodes?.[0] || "";
    const topColors = entries.archeeTopKolory
    dispatch(setTopColors(topColors))
    saveUrlsToLocalStorage(pageContext)
  }

  render() {
    const { data } = this.props
    const entries = data.entries?.nodes?.[0] || "";
    const sections = entries.archeeTrescPodstrony.filter(block => block.__typename === "Craft_archeeTrescPodstrony_component_BlockType")
    return (
      <>
        <GlobalStyles/>
        <StickyContainer>
          <TopBanner banner={entries.archeeBaner} topColors={entries.archeeTopKolory}/>
          <ComponentsFloatingMenu sections={sections}/>
          <ExtendedContent data={entries.archeeTrescPodstrony}/>
        </StickyContainer>
      </>
    )
  }
}

export default connect(state => ({
  topColors: state.app.topColors,
}), null)(ComponentsPage)


export const query = graphql`
    query($remoteId: ID, $langKey: String) {
            seo: allCraftArcheeComponentsArcheeComponentsEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}){
                nodes{
                    ...seoComponents
                }
            }
            entries: allCraftArcheeComponentsArcheeComponentsEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}){
                nodes {
                    title,
                    archeeTopKolory,
                    archeeBaner {
                        ...banner,
                    }
                    archeeTrescPodstrony {
                        __typename
                        ...margines,
                        ...naglowek,
                        ...naglowekIOpis,
                        ...component,
                        ...filmLink,
                        ...przycisk
                        ...modulyGraficzne
                        ...on Craft_archeeTrescPodstrony_naglowek2_BlockType {
                            naglowek2
                        }
                        ...on Craft_archeeTrescPodstrony_checkAlso_BlockType {
                            wybierzPodstrone{
                                title
                                __typename
                            }
                        }
                    }
                    seoTitle
                }
            }
    }`

export function Head({ data }) {
    const seoData = data?.seo?.nodes?.[0] || [];
    return (
        <SEO seoData={seoData}/>
    )
}
